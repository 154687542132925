// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import BaseService from '@/services/BaseService'
import { type AxiosResponse } from 'axios'
import { RootState } from '@/store'

export interface GlobalState {
    moduleName: string
    id: string
    token: string
    tenantId: number
    panelExpand: boolean
    users: any
}

const initialState: GlobalState = {
    moduleName: '',
    id: '0',
    token: '',
    tenantId: 0,
    panelExpand: false,
    users: []
}


export const getUsers = createAsyncThunk(
    '/micro-fe/users',
    async (
        _,
        { rejectWithValue, getState },
    ) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/users`,
            method: 'get',
            params: {
                tenantId: tenantId,
                token: token,
            },
        };

        try {
            const response: AxiosResponse = await BaseService(param);
            return response.data.users;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setModuleName: (state, action: PayloadAction<string>) => {
            state.moduleName = action.payload
        },
        setId: (state, action: PayloadAction<string>) => {
            state.id = action.payload
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        setTenantId: (state, action: PayloadAction<number>) => {
            state.tenantId = action.payload
        },
        setPanelExpand: (state, action: PayloadAction<boolean>) => {
            state.panelExpand = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.users = action.payload;
        });
    }
})

export const {
    setModuleName,
    setId,
    setToken,
    setTenantId,
    setPanelExpand
} = globalSlice.actions

export default globalSlice.reducer
