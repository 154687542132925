// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react'
import { Image } from 'primereact/image'
import { useDispatch, useSelector } from 'react-redux'
import { getThumbBlobName} from '@/utils'
import { RootState, setSelectedImage, setReviewImageThumbs} from '@/store'
import { Skeleton } from 'primereact/skeleton'
import useStorageAccess from '@/hooks/useStorageAccess'
import { useAppSelector } from '@/store'

export default function ReviewThumbs() {
    const dispatch = useDispatch()
    const storageAccess = useStorageAccess()
    const {
        reviewData,
        selectedTable,
        selectedImage,
        reviewImageThumbs
    } = useSelector((state: RootState) => state.review)
    

    const [isLoading, setIsLoading] = useState(true)

    /**
     * Blob URL create
     */
    const getBlobUrl = (blobName: string) => {
        if (!storageAccess.token) return null;
        const { storageName, token } = storageAccess;
        return token.replace('/?', `/${storageName}/${blobName}?`);
    }

    useEffect(() => {
        setIsLoading(true)
        if (reviewData?.assetDetail && reviewData?.assetDetail?.asset?.id) {
            const filterIns = reviewData.assetDetail.inspections?.find(
                (val) => val.assetId === reviewData?.assetDetail?.asset?.id
            );
            if (filterIns?.images) {
                const imageUrls = filterIns?.images?.map(image => ({
                    blobName: image.blobName,
                    url: getBlobUrl(getThumbBlobName(image.blobName)),
                    imageId: image.id
                }))
                dispatch(setReviewImageThumbs(imageUrls))
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        }
        return () => {
            dispatch(setReviewImageThumbs([]));
            setIsLoading(false)
        }
    }, [reviewData, storageAccess])

    const handleImageSelect = (index, inspectionId) => {
        dispatch(setSelectedImage({
            index,
            inspectionId
        }));
    };

    return (
        <>
            <div className="grid grid-cols-6 gap-1.5 h-full w-full">
                {isLoading && (
                    <div className="col-span-7 gap-2 flex flex-row w-full h-full">
                        {Array.from({ length: 5 }, (_, index) => (
                            <Skeleton
                                key={`skeleton-${index}`}
                                width="100vw"
                                height="100px"
                                borderRadius="16px"
                            />
                        ))}
                    </div>
                )}
                {!isLoading && storageAccess.token &&
                    reviewImageThumbs.map((image, index) => {
                        
                        const allImageIds = reviewData?.assetDetail?.detectionQuestions?.reduce((acc, question) => {
                            return [...acc, ...(question.imageIds || [])]
                        }, []) || []
                        
                        const isGeminiImage = allImageIds.some(id => 
                            image.imageId === id
                        )
                        
                        return (
                            <div
                                key={image.blobName}
                                className={`relative cursor-pointer h-[100px] ${selectedImage === index ? 'selected-border' : ''}`}
                                onClick={() => handleImageSelect(index, reviewData?.assetDetail?.inspections[0]?.id)}
                            >
                                {isGeminiImage && (
                                    <img
                                        src="/img/gemini-icon-2.png"
                                        className="absolute top-0 right-0 z-30"
                                        style={{ 
                                            width: '24px', 
                                            height: '24px', 
                                            transform: 'translate(30%, 0)',
                                            overflow: 'visible'
                                        }}
                                        alt="gemini-mark"
                                    />
                                )}
                                <Image
                                    src={image?.url}
                                    alt="Thumb"
                                    imageClassName="w-full object-cover rounded h-full"
                                />
                            </div>
                        );
                    })}
            </div>
        </>
    )
}
