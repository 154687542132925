// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { Button, ImageView } from '@/components/ui'
import { Skeleton } from 'primereact/skeleton'
import {
    getReviewImages,
    getTransmissionStructure,
    RootState,
    setReviewMainImage,
} from '@/store'
import { useDispatch, useSelector } from 'react-redux'
import { Image } from 'primereact/image'
import ReviewThumbs from './ReviewThumbs'
import useStorageAccess from '@/hooks/useStorageAccess'
import { HiOutlineThumbDown, HiOutlineThumbUp, HiZoomIn, HiZoomOut } from 'react-icons/hi'
import BaseService from '@/services/BaseService'
import type { AxiosResponse } from 'axios'
import { IoClose } from 'react-icons/io5'
import {getPreviewBlobName} from "@/utils";
import { Image as PrimeReactImage } from 'primereact/image';
import {
    setReviewMainImageLoading,
} from '@/store'
import { FeedbackButtons } from './FeedbackButtons'
import { MdCheck, MdOutlineCancel } from 'react-icons/md'
import { MdOutlineOpacity } from 'react-icons/md'
import { IoLayersSharp } from "react-icons/io5";
import { InputSwitch } from 'primereact/inputswitch';
import { Slider } from 'primereact/slider';

const TransmissionOptions = [
    { label: 'Pole: Monopole', value: 'Pole: Monopole' },
    { label: 'Pole: H-Frame', value: 'Pole: H-Frame' },
    { label: 'Pole: Lattice', value: 'Pole: Lattice' },
    { label: 'Insulator', value: 'Insulator' },
    { label: 'Cross Arm', value: 'Cross Arm' },
    { label: 'Transformer', value: 'Transformer' },
    { label: 'Arrestor', value: 'Arrestor' },
    { label: 'Ground Wire', value: 'Ground Wire' },
    { label: 'Guy Wire', value: 'Guy Wire' },
    { label: 'Conductor', value: 'Conductor' },
    { label: 'Static Wire', value: 'Static Wire' },
    { label: 'Steel Lattice', value: 'm_pole_silver_steel' },
    { label: 'Wooden Monopole', value: 'm_pole_wood' },
    { label: 'Weathered Monopole', value: 'm_pole_weathered_steel' },
    { label: 'Steel Monopole', value: 'hf_silver_steel' },
    { label: 'Wooden H-Frame', value: 'hf_wood' },
]

// Renk paleti ekleyelim
const TransmissionColors = [
    [255, 0, 0, 200],    // Kırmızı
    [0, 255, 0, 200],    // Yeşil
    [0, 0, 255, 200],    // Mavi
    [255, 255, 0, 200],  // Sarı
    [255, 0, 255, 200],  // Pembe
    [0, 255, 255, 200],  // Cyan
    [128, 0, 128, 200],  // Mor
    [255, 165, 0, 200]   // Turuncu
];

// Renk atama fonksiyonunu output bazında yapalım
const getColorForOutput = (outputIndex: number) => {
    return TransmissionColors[outputIndex % TransmissionColors.length];
};

const rleFromString = (encodedString) => {
    const characterCodes = encodedString
        .split('')
        .map((char) => char.charCodeAt(0))

    let codePointer = 0
    let ith = 0
    let count = 0
    let more = 0

    const counts = []

    while (characterCodes[codePointer]) {
        count = 0
        ith = 0
        more = 1

        while (more) {
            const char = characterCodes[codePointer] - 48
            count |= (char & 0x1f) << (5 * ith)
            more = char & 0x20
            codePointer++
            ith++
            if (!more && char & 0x10) count |= -1 << (5 * ith)
        }

        if (counts.length > 2) {
            count += counts[counts.length - 2]
        }
        counts.push(count)
    }

    return counts
}

function decodeCocoRLE([rows, cols], counts, flat = true) {
    let pixelPosition = 0,
        binaryMask

    if (flat) {
        binaryMask = Array(rows * cols).fill(0)
    } else {
        binaryMask = Array.from({ length: rows }, (_) => Array(cols).fill(0))
    }

    for (let i = 0, rleLength = counts.length; i < rleLength; i += 2) {
        let zeros = counts[i],
            ones = counts[i + 1] ?? 0

        pixelPosition += zeros

        while (ones > 0) {
            const rowIndex = pixelPosition % rows,
                colIndex = (pixelPosition - rowIndex) / rows

            if (flat) {
                const arrayIndex = rowIndex * cols + colIndex
                binaryMask[arrayIndex] = 1
            } else {
                binaryMask[rowIndex][colIndex] = 1
            }

            pixelPosition++
            ones--
        }
    }

    if (!flat) {
        console.log('Result matrix:')
        binaryMask.forEach((row, i) => console.log(row.join(' '), `- row ${i}`))
    }

    return binaryMask
}

const waitForImageLoad = (image: HTMLImageElement): Promise<void> => {
    return new Promise((resolve) => {
        if (image.complete) {
            resolve();
        } else {
            image.onload = () => resolve();
        }
    });
};

const transmissionLabelMap = TransmissionOptions.reduce((acc, curr) => {
    acc[curr.value] = curr.label
    return acc
}, {})

export default function ReviewImage() {
    const dispatch = useDispatch()
    const {
        reviewMainImage,
        reviewMainImageLoading,
        reviewData,
        selectedTable,
        selectedImage,
        selectedImageData,
        reviewType,
        maskImage,
        reviewShow
    } = useSelector((state: RootState) => state.review)

    const storageAccess = useStorageAccess()

    const { storageName, token } = storageAccess

    const imageRef = useRef(null)
    const commentRef = useRef(null)
    const canvasRef = useRef(null)

    const [canvasImage, setCanvasImage] = useState(null)
    const [isAILoading, setIsAILoading] = useState(false)
    const [mainImage, setMainImage] = useState(null)
    const [controller, setController] = useState<AbortController | null>(null);
    const [canvasLayers, setCanvasLayers] = useState<{ id: string; url: string; visible: boolean; order: number }[]>([]);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const isDragging = useRef(false);
    const startPos = useRef({ x: 0, y: 0 });
    const containerRef = useRef<HTMLDivElement>(null);

    // Pan state management
    const [isPanning, setIsPanning] = useState(false);
    const panStartPos = useRef({ x: 0, y: 0 });
    const panCurrentPos = useRef({ x: 0, y: 0 });

    const [showLayers, setShowLayers] = useState(false);
    const [showOpacitySlider, setShowOpacitySlider] = useState(false);
    const [opacityValue, setOpacityValue] = useState(0.85);

    const clamp = (value: number, min: number, max: number) => 
        Math.min(Math.max(value, min), max);

    const handlePanStart = useCallback((e: React.MouseEvent | TouchEvent) => {
        if (zoomLevel <= 1) return;
        
        setIsPanning(true);
        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        
        panStartPos.current = { x: clientX, y: clientY };
        panCurrentPos.current = { ...translate };

        document.body.style.cursor = 'grabbing';
    }, [zoomLevel, translate]);

    const handlePanMove = useCallback((e: MouseEvent | TouchEvent) => {
        if (!isPanning) return;

        const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
        const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
        
        const deltaX = clientX - panStartPos.current.x;
        const deltaY = clientY - panStartPos.current.y;

        // Calculate max pan boundaries based on zoom level
        const maxX = (containerRef.current?.offsetWidth || 0) * (zoomLevel - 1) / 2;
        const maxY = (containerRef.current?.offsetHeight || 0) * (zoomLevel - 1) / 2;

        setTranslate({
            x: clamp(panCurrentPos.current.x + deltaX, -maxX, maxX),
            y: clamp(panCurrentPos.current.y + deltaY, -maxY, maxY)
        });
    }, [isPanning, zoomLevel]);

    const handlePanEnd = useCallback(() => {
        if (!isPanning) return;
        
        setIsPanning(false);
        document.body.style.cursor = 'default';
    }, [isPanning]);

    useEffect(() => {
        const handleMouseUp = () => handlePanEnd();
        const handleMouseMove = (e: MouseEvent) => handlePanMove(e);
        const handleTouchMove = (e: TouchEvent) => handlePanMove(e);
        const handleTouchEnd = () => handlePanEnd();

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [handlePanMove, handlePanEnd]);

    const handleZoom = (direction: 'in' | 'out') => {
        setZoomLevel(prev => {
            const newZoom = direction === 'in' 
                ? Math.min(3, prev * 1.2) 
                : Math.max(0.5, prev * 0.8);
            return Number(newZoom.toFixed(2));
        });
    };

    useEffect(() => {
        if (controller && !controller.signal.aborted) {
            controller.abort();
        }

        const newController = new AbortController();
        setController(newController);

        // Reset canvas layers when image changes
        setCanvasLayers([]);
        setMainImage(null);
        setCanvasImage(null);
        resetCanvas();
        
        // Reset zoom and pan when image changes
        setZoomLevel(1);
        setTranslate({ x: 0, y: 0 });

        dispatch(setReviewMainImageLoading(true))
        setIsAILoading(false);

        if (!reviewShow || !selectedImageData) {
            dispatch(setReviewMainImageLoading(false))
            return;
        }

        const blobUrl = getBlobUrl(getPreviewBlobName(selectedImageData?.blobName));
        if (!blobUrl) {
            dispatch(setReviewMainImageLoading(false))
            return;
        }

        const imageElement = new window.Image();
        imageElement.crossOrigin = 'anonymous';
        imageElement.src = blobUrl;
        setMainImage(imageElement);
        dispatch(setReviewMainImageLoading(false));

        const handleImageProcessing = async () => {
            if (!reviewData?.assetDetail?.inspections?.length || !selectedImageData) return;
            if (!storageAccess.token || !storageAccess.storageName) return; 

            const inspection = reviewData.assetDetail.inspections.find(
                (val) => val.assetId === selectedTable.id
            );

            if (!inspection?.imageOutputs?.length) {
                setIsAILoading(false);
                return;
            }

            setIsAILoading(true);
            const newCanvasLayers: { id: string; url: string; visible: boolean }[] = [];

            const reviewOutages = inspection?.imageOutputs.filter(
                val => val.imageId == selectedImageData.id
            );

            console.log("reviewOutages", reviewOutages)

            for (const [outputIndex, output] of reviewOutages.entries()) {
                try {
                    if (output.maskBlobName) {
                        const maskResponse = await dispatch(
                            getTransmissionStructure({
                                file: output.maskBlobName,
                                storageName,
                                token,
                            })
                        ).unwrap();

                        if (maskResponse) {
                            const maskData = Array.isArray(maskResponse) 
                                ? maskResponse 
                                : [maskResponse];
                            
                            // Output bazında renk belirleme
                            const outputColor = getColorForOutput(outputIndex);

                            maskData.forEach((mask, wireIndex) => {
                                try {
                                    const counts = rleFromString(mask.counts);
                                    const size = mask.size || [mask.height, mask.width];
                                    
                                    const score = output.scores?.[wireIndex] ?? 0;
                                    const label = output.labels?.[wireIndex] || 'wire';

                                    const canvasUrl = drawWireMask({
                                        size,
                                        counts,
                                        label,
                                        score,
                                        imageElement,
                                        color: outputColor
                                    });
                                    
                                    if (canvasUrl) {
                                        newCanvasLayers.push({
                                            id: `${output.mlModelName}-${output.mlOutputId}-${wireIndex}`,
                                            url: canvasUrl,
                                            visible: true,
                                            color: outputColor // Layer'a renk bilgisini ekliyoruz
                                        });
                                    }
                                } catch (e) {
                                    console.error(`Wire ${wireIndex} processing error:`, e);
                                }
                            });
                        }
                    } else if (output.detections?.length) {
                        const newLayers = await handleDraw(true, imageElement, output, outputIndex);
                        newCanvasLayers.push(...newLayers);
                    }
                } catch (error) {
                    console.error('Output processing error:', output.id, error);
                }
            }

            setCanvasLayers(newCanvasLayers);
            setIsAILoading(false);
        };

        const loadHighQualityImage = (blobUrl: string, signal: AbortSignal) => {
            const qualityImageElement = new window.Image();
            qualityImageElement.crossOrigin = 'anonymous';
            qualityImageElement.src = blobUrl;

            const onLoad = () => {
                if (signal.aborted) return;
                setMainImage(qualityImageElement);
                setCanvasImage(null);
                //handleDraw(false, qualityImageElement);
            };

            const onError = () => {
                if (signal.aborted) return;
                console.error('High-quality image loading failed');
            };

            qualityImageElement.onload = onLoad;
            qualityImageElement.onerror = onError;

            signal.addEventListener('abort', () => {
                qualityImageElement.onload = null;
                qualityImageElement.onerror = null;
                qualityImageElement.src = '';
            });
        };

        imageElement.onload = () => {
            handleImageProcessing();
            const blobUrl = getBlobUrl(selectedImageData?.blobName);
            if (!blobUrl) return;
            loadHighQualityImage(blobUrl, newController.signal);
        }
        imageElement.onerror = () => {
            dispatch(setReviewMainImageLoading(false))
            console.error('Image loading failed');
        };

        return () => {
            setMainImage(null);
            setCanvasImage(null);
            resetCanvas();
            setIsAILoading(false);
            dispatch(setReviewMainImageLoading(false))
            newController.abort();
        };
    }, [reviewData, selectedImage, selectedImageData, reviewShow, storageAccess]);

    /**
     * Resets the canvas by clearing its context and setting its dimensions to zero.
     */
    function resetCanvas() {
        if (canvasRef.current) {
            setCanvasImage(null);

            // 2D context'i temizle
            const ctx = canvasRef.current.getContext('2d', {
                willReadFrequently: true
            });
            if (ctx) {
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            }

            // WebGL context'i dispose et
            try {
                const gl = canvasRef.current.getContext('webgl');
                if (gl) {
                    gl.getExtension('WEBGL_lose_context')?.loseContext();
                }
            } catch (e) {
                console.warn('WebGL context cleanup failed:', e);
            }

            canvasRef.current.width = 0;
            canvasRef.current.height = 0;
        }
    }

       /**
     * Draws bounding boxes and confidence scores on the canvas based on the image predictions.
     * Scales and adjusts the drawing based on whether the image is a preview or full-resolution.
     *
     * @param {boolean} preview - Whether the image being drawn is a preview.
     */
       async function handleDraw(preview: boolean, imageElement: HTMLImageElement, outage: any, outputIndex: number) {
        const outputColor = getColorForOutput(outputIndex);
    
        const rgbaColor = `rgba(${outputColor[0]}, ${outputColor[1]}, ${outputColor[2]}, 255)`;

        try {
          
            const imgPrediction = {
                b_box: outage.detections.length === 4 ? [[
                    [outage.detections[0], outage.detections[1]],
                    [outage.detections[2], outage.detections[3]]
                ]] : outage.detections,
                conf_list: outage.scores,
                label_list: outage.labels.map(label => transmissionLabelMap[label] || label)
            };

            if (!imageElement || !imgPrediction.b_box.length) {
                setCanvasImage(null);
                return [];
            }

            await waitForImageLoad(imageElement);

            const width = imageElement.naturalWidth;
            const height = imageElement.naturalHeight;
            const naturalWidth = preview ? selectedImageData.width : width;
            const multiplier = width / naturalWidth;

            const newLayers = [];

            for (let i = 0; i < imgPrediction.b_box.length; i++) {
                const tempCanvas = document.createElement('canvas');
                const scaleFactor = window.devicePixelRatio || 1; // Retina display support
                
                // Yüksek çözünürlüklü canvas ayarları
                tempCanvas.width = width * scaleFactor;
                tempCanvas.height = height * scaleFactor;
                tempCanvas.style.width = `${width}px`;
                tempCanvas.style.height = `${height}px`;
                
                const ctx = tempCanvas.getContext('2d');
                ctx.scale(scaleFactor, scaleFactor); // Çözünürlük scaling
                ctx.imageSmoothingEnabled = true; // Anti-aliasing aktif
                
                // Bounding box çizimi
                const bbox = imgPrediction.b_box[i];
                const x1 = Array.isArray(bbox[0]) ? bbox[0][0] * multiplier : bbox[0] * multiplier;
                const y1 = Array.isArray(bbox[0]) ? bbox[0][1] * multiplier : bbox[1] * multiplier;
                const x2 = Array.isArray(bbox[1]) ? bbox[1][0] * multiplier : bbox[2] * multiplier;
                const y2 = Array.isArray(bbox[1]) ? bbox[1][1] * multiplier : bbox[3] * multiplier;

                ctx.strokeStyle = rgbaColor;
                ctx.lineWidth = 5;
                ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

                // Etiket çizimi
                if (imgPrediction.label_list?.[i]) {
                    const conf = (imgPrediction.conf_list[i] * 100).toFixed(0);
                    const text = `${imgPrediction.label_list[i]} ${conf}%`;
                    
                    // Yazı kalitesi ayarları
                    ctx.font = '13px Roboto, Arial, sans-serif';
                    ctx.textRendering = 'geometricPrecision'; // Metin anti-aliasing
                    const textMetrics = ctx.measureText(text);
                    const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
                    const padding = 2 * scaleFactor;
                    const verticalPadding = 0.3 * scaleFactor;
                    
                    // Arkaplan boyutları
                    const labelHeight = textHeight + verticalPadding * 2;
                    const labelWidth = textMetrics.width + padding * 2;
                    
                    // Dikey pozisyon hesaplama
                    let labelY = y1 - labelHeight - 2;

                    // Üstte yer yoksa altı kontrol et
                    if (labelY < 5) {
                        labelY = y2 + 2;
                        
                        // Altta da yer yoksa üste zorla (minimum 2px boşluk)
                        if (labelY + labelHeight > height - 2) {
                            labelY = Math.max(2, y1 - labelHeight - 2);
                        }
                    }

                    // Yatay pozisyon sınırlamaları
                    const labelX = Math.max(
                        2, // Minimum sol boşluk
                        Math.min(
                            x1 + (x2 - x1)/2 - labelWidth/2,
                            width - labelWidth - 2 // Minimum sağ boşluk
                        )
                    );

                    // Arkaplan çizimi
                    ctx.fillStyle = rgbaColor;
                    ctx.beginPath();
                    ctx.roundRect(
                        labelX,
                        labelY,
                        labelWidth,
                        labelHeight,
                        2 // Hafif yuvarlak köşe
                    );
                    ctx.fill();

                    // Metin çizimi
                    ctx.fillStyle = 'rgba(255, 255, 255, 1)';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    ctx.fillText(
                        text, 
                        labelX + labelWidth/2,
                        labelY + labelHeight - verticalPadding
                    );

                    newLayers.push({
                        id: `bbox-${outage.mlModelName}-${Date.now()}-${i}`,
                        url: tempCanvas.toDataURL(),
                        visible: true,
                        color: getColorForOutput(outputIndex)
                    });
                }
            }

            return newLayers;
        } catch (error) {
            console.error('Canvas drawing error:', error);
            setCanvasImage(null);
            return [];
        }
    }

    const getBlobUrl = (blobName: string) => {
        if (!storageAccess?.token || !blobName || !storageName) return null;
        return storageAccess.token.replace('/?', `/${storageName}/${blobName}?`);
    }

    // Wire mask çizim fonksiyonu
    const drawWireMask = ({ size, counts, label, score, imageElement, color }) => {
        const [height, width] = size;
        const maskFlattened = decodeCocoRLE(size, counts);
        
        const maskColor = color;
        
        // Canvas oluştur
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = width;
        tempCanvas.height = height;
        const ctx = tempCanvas.getContext('2d');
        
        // Maskeyi çiz
        const imageData = new ImageData(width, height);
        const data = imageData.data;
        
        let minX = width, maxX = 0, minY = height, maxY = 0;
        let pixelCount = 0;
        let sumX = 0, sumY = 0;

        for (let i = 0; i < maskFlattened.length; i++) {
            if (maskFlattened[i] === 1) {
                const y = Math.floor(i / width);
                const x = i % width;
                
                // Merkez hesaplama için
                minX = Math.min(minX, x);
                maxX = Math.max(maxX, x);
                minY = Math.min(minY, y);
                maxY = Math.max(maxY, y);
                
                // Pixel pozisyonu
                const idx = (y * width + x) * 4;
                
                // Mask rengini uygula (alpha blending)
                data[idx] = maskColor[0];   // R
                data[idx + 1] = maskColor[1]; // G
                data[idx + 2] = maskColor[2]; // B
                data[idx + 3] = maskColor[3]; // A
                
                // Merkez hesaplama için toplam
                sumX += x;
                sumY += y;
                pixelCount++;
            }
        }

        // Merkez koordinatları
        const centroidX = pixelCount > 0 ? Math.round(sumX / pixelCount) : (minX + maxX) / 2;
        const centroidY = pixelCount > 0 ? Math.round(sumY / pixelCount) : (minY + maxY) / 2;

        // Maskeyi canvas'a uygula
        ctx.putImageData(imageData, 0, 0);

        // Etiket çizimi
        if (label && score) {
            ctx.font = '40px roboto';
            const text = `${label} ${Math.round(score * 100)}%`;
            const textWidth = ctx.measureText(text).width;
            const textHeight = 40;
            
            // Çakışma önleme offset'i
            let yOffset = 0;
            const minVerticalDistance = 60;
            
            // Önceki etiketlerle karşılaştır (sadece dikey mesafe)
            if (window.__prevLabels) {
                window.__prevLabels.forEach(prev => {
                    const verticalDiff = Math.abs(centroidY - prev.y);
                    // Sadece dikey yakınlık kontrolü
                    if (verticalDiff < minVerticalDistance) {
                        yOffset = Math.max(yOffset, prev.height + 10);
                    }
                });
            } else {
                window.__prevLabels = [];
            }

            // Arkaplan
            ctx.fillStyle = `rgba(${maskColor[0]}, ${maskColor[1]}, ${maskColor[2]}, ${maskColor[3]/255})`;
            ctx.beginPath();
            ctx.roundRect(
                centroidX - textWidth/2 - 10,
                centroidY - textHeight - 5 - yOffset,
                textWidth + 20,
                textHeight + 10,
                8
            );
            ctx.fill();
            
            // Metin
            ctx.fillStyle = '#fff';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';
            ctx.fillText(text, centroidX, centroidY - 5 - yOffset);

            // Pozisyonu kaydet (sadece Y ekseni)
            window.__prevLabels.push({
                y: centroidY - yOffset,
                height: textHeight + 10
            });

            // Renk geçmişini sakla
            if (!window.__prevColors) window.__prevColors = new Map();
            window.__prevColors.set(label, maskColor);
        }

        return tempCanvas.toDataURL();
    };

    const toggleLayerVisibility = (layerId: string) => {
        setCanvasLayers(prevLayers => 
            prevLayers.map(layer => 
                layer.id === layerId ? { ...layer, visible: !layer.visible } : layer
            )
        );
    };


    const handleOpacityChange = (value: number) => {
        const normalizedOpacity = Math.min(1, Math.max(0.1, value / 100)) // 0.1-1 arası
        setOpacityValue(normalizedOpacity)
        
        setCanvasLayers(prevLayers => 
            prevLayers.map(layer => ({
                ...layer,
                style: { opacity: normalizedOpacity }
            }))
        )
    };

    useEffect(() => {
        setCanvasLayers(prevLayers => 
            prevLayers.map(layer => ({
                ...layer,
                style: { opacity: 0.85 }
            }))
        )
    }, []); 

    return (
        <div className="rounded-md mt-4 absolute w-full">
            <canvas
                ref={canvasRef}
                id="myCanvas"
                width={0}
                height={0}
                style={{ display: 'none' }}
                crossOrigin="anonymous"
            ></canvas>
            <div className="grid grid-rows-11 gap-2 h-[calc(100vh-100px)]">
                <div className="row-span-9 overflow-hidden rounded-xl relative">
                    {!mainImage && (
                        <Skeleton
                            width="100vw"
                            height="100vh"
                            borderRadius="16px"
                        ></Skeleton>
                    )}
                    {mainImage && (
                        <div 
                            ref={containerRef}
                            className="relative w-full h-full select-none"
                            onMouseDown={handlePanStart}
                            onTouchStart={handlePanStart}
                            style={{ 
                                cursor: zoomLevel > 1 ? 'grab' : 'default',
                                touchAction: 'none'
                            }}
                        >
                            {/* Zoom controls */}
                            <div className="absolute top-0 right-5 rotate-image-header flex gap-2">
                                 {/* Katmanlar butonu */}
                                 <Button
                                    variant="filled"
                                    size="xs"
                                    icon={<IoLayersSharp className="text-lg" />}
                                    onClick={() => setShowLayers(!showLayers)}
                                    className="relative"
                                />
                                <Button
                                    variant="filled"
                                    size="xs"
                                    icon={<HiZoomOut className="text-xl" />}
                                    onClick={() => handleZoom('out')}
                                />
                                <Button
                                    variant="filled"
                                    size="xs"
                                    icon={<HiZoomIn className="text-xl" />}
                                    onClick={() => handleZoom('in')}
                                />
                            </div>

                            {/* Katmanlar pop-up */}
                            {showLayers && (
                                <div className="absolute right-5 top-12 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-xl border border-gray-200 dark:border-gray-700 z-50 w-72">
                                    <div className="flex items-center justify-between mb-3">
                                        <div className="flex items-center gap-2">
                                            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Detections</h3>
                                            <Button
                                                variant="filled"
                                                size="xs"
                                                icon={<MdOutlineOpacity className={`text-lg ${showOpacitySlider && 'text-trueBlue'}`} />}    
                                                onClick={() => setShowOpacitySlider(!showOpacitySlider)}
                                            />
                                            <button 
                                                onClick={() => setCanvasLayers(prev => prev.map(l => ({...l, visible: !prev.some(l => l.visible)})))}
                                                className="px-2 py-1 text-sm bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                                            >
                                                {canvasLayers.some(l => l.visible) ? 'Hide all' : 'Show all'}
                                            </button>
                                        </div>
                                        <IoClose 
                                            className="text-xl cursor-pointer text-gray-500 hover:text-gray-700 dark:text-gray-300"
                                            onClick={() => setShowLayers(false)}
                                        />
                                    </div>
                                    
                                    {/* Layers list with scroll */}
                                    <div className="space-y-2 max-h-60 overflow-y-auto mb-2">
                                        {Array.from(
                                            canvasLayers.reduce((groupMap, layer) => {
                                                // Model ismini ilk iki bölüme göre grupla (Ör: "OG-Wire-Mobile" -> "OG-Wire")
                                                const modelNameMatch = layer.id.match(/^([^-]+-[^-]+)/);
                                                const groupId = modelNameMatch ? modelNameMatch[1] : layer.id.split('-')[0];
                                                
                                                if (!groupMap.has(groupId)) {
                                                    groupMap.set(groupId, {
                                                        id: groupId,
                                                        visible: layer.visible,
                                                        count: 0,
                                                        color: layer.color // Renk bilgisini gruplara ekliyoruz
                                                    });
                                                }
                                                const group = groupMap.get(groupId);
                                                group.count++;
                                                group.visible = group.visible && layer.visible;
                                                return groupMap;
                                            }, new Map()).values()
                                        ).map((group) => (
                                            <div 
                                                key={group.id}
                                                className="flex items-center gap-3 p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md transition-colors cursor-pointer border-b border-gray-100 dark:border-gray-700"
                                                onClick={() => {
                                                    const allVisible = canvasLayers
                                                        .filter(l => l.id.startsWith(group.id))
                                                        .every(l => l.visible);
                                                        
                                                    setCanvasLayers(prev => 
                                                        prev.map(layer => 
                                                            layer.id.startsWith(group.id) 
                                                                ? {...layer, visible: !allVisible} 
                                                                : layer
                                                        )
                                                    );
                                                }}
                                            >
                                                {/* Renk göstergesi */}
                                                <div 
                                                    className={`w-3 h-3 rounded-full transition-colors`}
                                                    style={{ 
                                                        backgroundColor: group?.visible 
                                                            ? `rgba(${group?.color?.join(',')})` 
                                                            : '#9CA3AF',
                                                        opacity: group?.visible ? 1 : 0.5
                                                    }}
                                                />
                                                <div className="flex-1 max-w-[150px]">
                                                    <div title={group.id} className="text-sm font-medium text-gray-700 dark:text-gray-200 text-truncate max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis">
                                                        {group.id}
                                                    </div>
                                                    <div className="text-xs text-gray-500 dark:text-gray-400">
                                                        {group.count} {group.count > 1 ? 'layers' : 'layer'}
                                                    </div>
                                                </div>
                                                <span className="text-xs text-gray-500 dark:text-gray-400">
                                                    <InputSwitch 
                                                        checked={group.visible} 
                                                        className="scale-75 custom-inputswitch"
                                                    />
                                                </span>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Opacity slider integrated within layers popup */}
                                    {showOpacitySlider && (
                                        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
                                            <div className="flex items-center justify-between mb-2">
                                                <span className="text-sm text-gray-700 dark:text-gray-300">Opacity</span>
                                                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                                    {Math.round(opacityValue * 100)}%
                                                </span>
                                            </div>
                                            <Slider 
                                                value={opacityValue * 100}
                                                onChange={(e) => handleOpacityChange(e.value)}
                                                className="w-full custom-opacity-slider"
                                                min={10}
                                                max={100}
                                                step={5} 
                                            />
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Image container */}
                            <div 
                                className="absolute top-0 left-0 w-full h-full will-change-transform"
                                style={{
                                    transform: `translate3d(${translate.x}px, ${translate.y}px, 0) scale(${zoomLevel})`,
                                    transformOrigin: 'center center',
                                    transition: isPanning ? 'none' : 'transform 0.2s ease-out'
                                }}
                            >
                                <img
                                    ref={imageRef}
                                    src={mainImage.src}
                                    className="w-full h-full object-contain"
                                  
                                />
                                
                                {canvasLayers.map(layer => (
                                    <img
                                        key={layer.id}
                                        src={layer.url}
                                        className="absolute top-0 left-0 w-full h-full object-contain pointer-events-none"
                                        style={{ 
                                            visibility: layer.visible ? 'visible' : 'hidden',
                                            opacity: layer.style?.opacity ?? 1 // Opacity değerini buradan al
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {/* AI Processing Loading Indicator */}
                    {isAILoading && (
                        <div className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full flex items-center space-x-2">
                            <span className="text-sm font-medium">AI</span>
                            <div className="relative flex items-center justify-center">
                                {/* Center dot */}
                                <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse" />

                                {/* Expanding rings */}
                                <div className="absolute w-2 h-2 bg-green-400 rounded-full animate-ping" />
                                <div className="absolute w-2 h-2 bg-green-400 rounded-full animate-ping delay-150" />
                            </div>
                        </div>
                    )}
                    <FeedbackButtons />
                </div>
                <div className="row-span-2 pl-2 w-full h-full overflow-y-scroll">
                    <ReviewThumbs />
                </div>
            </div>
        </div>
    )
}
