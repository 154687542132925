// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useAppSelector } from '@/store'
import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { AxiosResponse } from 'axios'
import BaseService from '@/services/BaseService'
import { HiOutlineThumbUp, HiOutlineThumbDown } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { Button } from '@/components/ui'
import { MdCheck } from 'react-icons/md'

export function FeedbackButtons() {
    const token = useAppSelector((state) => state.global.token);
    const tenantId = useAppSelector((state) => state.global.tenantId);

    const [showPopup, setShowPopup] = useState(false)
    const [thumbsStatus, setThumbsStatus] = useState([])

    const [customReason, setCustomReason] = useState('')
    const [showCustomReasonPopup, setShowCustomReasonPopup] = useState(false)
    const [showInput, setShowInput] = useState(false)

    const { selectedTable, selectedImage, reviewData } = useSelector(
        (state: RootState) => state.review,
    )

    const inspection = reviewData?.assetDetail?.inspections?.find(
        (val) => val.assetId == selectedTable?.id,
    )

    useEffect(() => {
        if (reviewData?.assetDetail?.asset?.id) {
            getThumbsDownReasons()
        }
    }, [reviewData])

    // Memoized feedback status of the current image
    const currentImageHasFeedback = useMemo(() => {
        if (!thumbsStatus) return null
        return (
            thumbsStatus?.filter(
                (item) =>
                    item?.imageId === inspection?.images?.[selectedImage]?.id,
            ) || null
        )
    }, [thumbsStatus, inspection, selectedImage])

    /**
     * Creates the feedback data object with details about the image and the feedback provided.
     * Optionally includes a reason for negative feedback.
     *
     * @param {boolean} feedback - The feedback value (true for positive, false for negative)
     * @param {string} [reason=''] - The reason for negative feedback (optional)
     * @returns {object} The feedback data object
     */
    const createFeedbackData = (feedback, reason = '') => ({
        assetId: inspection?.images?.[selectedImage]?.assetId,
        imageId: inspection?.images?.[selectedImage]?.id,
        contentType: inspection?.images?.[selectedImage]?.contentType,
        blobName: inspection?.images?.[selectedImage]?.blobName,
        feedback,
        reason,
        token,
        tenantId
    })

    /**
     * Retrieves the list of thumbs-down feedback reasons for the current asset.
     * Updates the state with the retrieved feedback data.
     */
    async function getThumbsDownReasons() {
        try {
            const param = {
                url: `/micro-fe/feedbacks?assetId=${reviewData?.assetDetail?.asset?.id}&token=${token}&tenantId=${tenantId}`,
                method: 'get',
                params: {},
            }
            const response: AxiosResponse<Response> = await BaseService(param)
            setThumbsStatus(response.data?.feedbacks)
        } catch (error) {
            console.error('Error fetching thumbs down reasons:', error)
            setThumbsStatus([])
        }
    }

    /**
     * Sends the feedback data to the server.
     * Sets the loading state during the request and marks all images as reviewed after submission.
     *
     * @param {object} data - The feedback data object to be sent
     */
    const sendFeedback = async (data) => {
        try {
            await BaseService({
                url: '/micro-fe/create-feedback',
                method: 'post',
                data,
            })
        } finally {
            getThumbsDownReasons()
        }
    }

    /**
     * Deletes feedback with the specified ID and refreshes the list of thumbs-down reasons.
     *
     * @param {number|string} id - The ID of the feedback to delete.
     */
    const deleteFeedback = async (id) => {
        try {
            await BaseService({
                url: `/micro-fe/delete-feedback/${id}?tenantId=${tenantId}&token=${token}`,
                method: 'delete',
            })
        } catch (error) {
            console.error(error)
        } finally {
            getThumbsDownReasons()
        }
    }

     /**
     * Handles the thumb action by sending or deleting feedback based on the current state.
     * Specifically, this function handles positive feedback and toggles a popup for negative feedback.
     *
     * @param {boolean} thumbFeedback - The feedback value:
     *   - `true` for positive feedback (thumbs up)
     *   - `false` for negative feedback (thumbs down)
     */
     const handleThumbAction = (thumbFeedback: boolean) => {
        if (thumbFeedback) {
            const feedbackData = createFeedbackData(thumbFeedback)
            const existingPositive = currentImageHasFeedback.find(f => f.feedback === true)
            
            if (existingPositive) {
                deleteFeedback(existingPositive.id)
            } else {
                currentImageHasFeedback.forEach(feedback => {
                    if(feedback.feedback === false) {
                        deleteFeedback(feedback.id)
                    }
                })
                sendFeedback(feedbackData)
            }
        } else {
            setShowPopup(prev => !prev)
        }
    }

    
    /**
     * Handles the selection of a reason for negative feedback.
     * If there is existing positive feedback for the same reason, it is deleted.
     * It then handles the negative feedback by either deleting existing negative feedback
     * or sending new negative feedback based on the current state.
     * Finally, it closes the feedback popup.
     *
     * @param {string} reason - The reason for the negative feedback.
     */
    const handleReasonSelect = (reason: string) => {
        if (reason === 'Other') {
            const existing = currentImageHasFeedback.find(f => 
                f.feedback === false &&
                !feedbackReasons.slice(0, -1).includes(f.reason)
            )
            
            if (existing) {
                deleteFeedback(existing.id)
            } else {
                setShowInput(true)
            }
            return
        }
    
        const existing = currentImageHasFeedback.find(f => f.reason === reason)
        if (existing) {
            deleteFeedback(existing.id)
        } else {
            sendFeedback(createFeedbackData(false, reason))
            currentImageHasFeedback.forEach(feedback => {
                if(feedback.feedback === true) {
                    deleteFeedback(feedback.id)
                }
            })
        }
    }

    /**
     * Closes the reason selection popup.
     */
    const handlePopupClose = () => {
        setShowPopup(false)
    }

    const isPositiveFeedback = currentImageHasFeedback?.some(
        (feed) => feed?.feedback === true,
    )
    const isNegativeFeedback = currentImageHasFeedback?.some(
        (feed) => feed?.feedback === false,
    )

    const feedbackReasons = ['Missed Asset', 'Asset wrongly identified', 'Other']

    const handleCustomReasonSubmit = () => {
        if (customReason.trim()) {
            const positiveFeedback = currentImageHasFeedback.find(f => f.feedback === true)
            if(positiveFeedback) {
                deleteFeedback(positiveFeedback.id)
            }
            
            sendFeedback(createFeedbackData(false, customReason))
            setCustomReason('')
            setShowInput(false)
        }
    }

    return (
        <div className="flex space-x-2 z-50 absolute bottom-4 left-1/2 transform -translate-x-1/2">
            {/* Button for positive feedback (Thumbs Up) */}
            <Button
                variant="custom"
                size="xs"
                color={`${isPositiveFeedback ? 'trueBlue' : 'chineseWhite'}`}
                textColor={`${isPositiveFeedback ? 'text-white' : 'text-gray'}`}
                icon={<HiOutlineThumbUp className="text-xl" />}
                onClick={() => handleThumbAction(true)}
            />

            {/* Button for negative feedback (Thumbs Down) */}
            <Button
                variant="custom"
                size="xs"
                color={`${isNegativeFeedback ? 'trueBlue' : 'chineseWhite'}`}
                textColor={`${isNegativeFeedback ? 'text-white' : 'text-gray'}`}
                icon={<HiOutlineThumbDown className="text-xl" />}
                onClick={() => handleThumbAction(false)}
            />

            {/* Reasons popup for negative feedback */}
            {showPopup && (
                <div className="absolute -top-20 left-1/2 transform -translate-x-1/2 z-50 w-[400px]">
                    <div className="relative -bottom-[25px] left-[15px] bg-white shadow-lg rounded-lg p-2 flex flex-col items-center">
                        <div className="absolute -bottom-[4px] left-1/5 transform -translate-x-1/2 w-0 h-0 border-x-4 border-x-transparent border-t-4 border-t-white"></div>
                        <div className="flex flex-row m-0 gap-1 w-[360px]">
                        {!showInput && feedbackReasons.map((reason) => {
                            const isOther = reason === 'Other';
                            const isActive = !isPositiveFeedback && (isOther 
                                ? currentImageHasFeedback.some(f => 
                                    f.feedback === false && 
                                    !feedbackReasons.slice(0, -1).includes(f.reason)
                                )
                                : currentImageHasFeedback.some(f => 
                                    f.feedback === false && 
                                    f.reason === reason
                                ))
                            
                            const otherReasons = currentImageHasFeedback
                                .filter(f => !feedbackReasons.slice(0, -1).includes(f.reason))
                                .map(f => f.reason);

                         
                            return (
                                <div key={reason} className="relative group">
                                    <Button
                                        size="xs"
                                        variant="twoTone"
                                        textColor="text-arsenic"
                                        className={`px-3 py-1 text-sm ${
                                            isActive 
                                                ? 'bg-blue-100 hover:bg-blue-200' 
                                                : 'bg-gray-100 hover:bg-gray-200'
                                        } rounded transition-colors duration-200`}
                                        active={isActive}
                                        onClick={() => handleReasonSelect(reason)}
                                    >
                                        {(isOther && isActive && otherReasons.length > 0) 
                                            ? `${otherReasons
                                                .map(r => r.length > 5 ? r.slice(0,5)+'...' : r)
                                                .join(', ')
                                                .slice(0, 5)}...` 
                                            : reason}
                                    </Button>
                                    
                                    {isOther && isActive && otherReasons?.length > 0 && (
                                        <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 hidden group-hover:block">
                                            <div className="bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap">
                                                {otherReasons.join(', ')}
                                                <div className="absolute w-2 h-2 bg-gray-800 rotate-45 -bottom-1 left-1/2 -translate-x-1/2"></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}

                            {showInput && (
                                <div className="flex flex-row gap-2 items-center w-full">
                                    <div className="flex flex-row relative w-[300px]">
                                        <input
                                            type="text"
                                            value={customReason}
                                            onChange={(e) => setCustomReason(e.target.value)}
                                            onKeyDown={(e) => {
                                                if(e.key === 'Enter') {
                                                    handleCustomReasonSubmit()
                                                }
                                            }}
                                            className="border rounded px-2 py-1 text-sm w-full pr-10"
                                            placeholder="Enter reason..."
                                        />
                                        <div className="absolute right-1 top-1/2 -translate-y-1/2 flex gap-1 bg-white"> 
                                            <Button
                                                size="xs"
                                                variant="custom"
                                                onClick={handleCustomReasonSubmit}
                                                className="p-1 h-auto text-green-500 hover:text-green-600"
                                            >
                                                <MdCheck className="text-base" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <Button
                                size="xs"
                                variant="custom"
                                onClick={handlePopupClose}
                                onKeyDown={(e) => {
                                    if(e.key === 'Enter') handlePopupClose()
                                }}
                                className="ml-2"
                            >
                                <IoClose className="text-xl" />
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}