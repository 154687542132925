// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { RootState } from '@/store'
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { updateDetectionQuestion, setSelectedDetection, setSelectedImage, approveDetection } from "@/store/slices/dashboard/reviewSlice";
import Button from "@/components/ui/Button";
import { AppDispatch } from '@/store';
import StatusBadge from '@/modules/review-2/general/StatusBadge'
import { DETECTION_TYPE_MAPPING} from '@/utils'
import { useAppSelector } from '@/store';

interface ActionButtonsProps {
    isActive: boolean;
    handleSubmit: () => void;
    handleReject: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ isActive, handleSubmit, handleReject }) => (
    <div className="flex flex-row w-full gap-2 pt-2 mt-auto">
        <Button
            shape="round"
            variant="custom"
            size="sm"
            color="gray-200"
            textColor="text-arsenic"
            className="w-full"
            onClick={handleReject}
        >
            Cancel
        </Button>
        <Button
            shape="round"
            variant="custom"
            size="sm"
            color="trueBlue"
            textColor="text-white"
            className="w-full"
            disabled={isActive}
            onClick={handleSubmit}
        >
            Approve
        </Button>
    </div>
)

interface DetectionQuestion {
    id: number;
    tenantId: number;
    detectionType: string;
    name: string;
    options: string[];
    orderPoint: number;
    parentId: null | number;
    visibleValues: null | string[];
    forSubAsset: boolean;
    hasSubAssetCount: boolean;
}

interface DetectionQuestionResponse {
    id: number;
    aiScore: number;
    aiValue: string;
    assetId: number;
    questionId: number;
}

export const DetectionForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<Record<string, any>>({});
    const { questionValues, filteredQuestions, selectedDetection, reviewImageThumbs, reviewData, selectedTable } = useSelector((state: RootState) => state.review);
    const usernames = useAppSelector(state => state.global.users)

    const updateVisibleQuestions = useCallback(() => {
        if (!filteredQuestions?.length) return;

        const updatedFormData = { ...formData };
        let hasChanges = false;

        filteredQuestions.forEach((question: DetectionQuestion) => {
            if (question?.parentId) {
                const parentQuestion = filteredQuestions.find(q => q.id === question.parentId);
                if (parentQuestion) {
                    const parentValue = formData[parentQuestion.id];
                    if (!question.visibleValues?.includes(parentValue) && formData[question.id] !== undefined) {
                        delete updatedFormData[question.id];
                        hasChanges = true;
                    }
                }
            }
        });

        if (hasChanges) {
            setFormData(updatedFormData);
        }
    }, [formData, filteredQuestions]);

    useEffect(() => {
        updateVisibleQuestions();
    }, [updateVisibleQuestions]);

    useEffect(() => {
        if (questionValues?.length && filteredQuestions?.length) {
            const initialFormData = { ...formData };

            filteredQuestions.forEach((question: DetectionQuestion) => {
                const questionValue = questionValues.find((q: any) => q.questionId === question.id);
                if (questionValue) {
                    const value = questionValue.userValue || questionValue.aiValue;
                    if (value) {
                        initialFormData[question.id] = {
                            label: value,
                            value: value
                        };
                    }
                }
            });

            setFormData(initialFormData);
        }
    }, [questionValues, filteredQuestions]);

    useEffect(() => {
        if (filteredQuestions && reviewImageThumbs?.length) {
            const firstImageId = questionValues?.[0]?.imageIds?.[0];
      
            const imageIndex = reviewImageThumbs.findIndex(img => 
                img.imageId === firstImageId
            );
            
            if (imageIndex !== -1) {
                dispatch(setSelectedImage({ 
                    index: imageIndex,
                    reviewData: reviewData,
                    selectedTable: selectedTable
                }));
            }
        }
    }, [questionValues, reviewImageThumbs]);
    const handleChange = useCallback((questionId: number, value: string, question: any) => {
        if (!questionId) return;

        const updatedData = {
            ...formData,
            [questionId]: {
                label: value,
                value: value
            }
        };

        dispatch(updateDetectionQuestion({
            id: questionValues[0].id || 0,
            userValue: value || ''
        }))

        setFormData(updatedData);
    }, [formData, dispatch]);

    const generateDropdownOptions = useCallback((options: string[] = []) => {
        return options.map(option => ({
            label: option,
            value: option
        }));
    }, []);

    const isQuestionVisible = useCallback((question: DetectionQuestion): boolean => {
        if (!question) return false;
        if (!question.parentId || !question.visibleValues) return true;

        const parentValue = formData[question.parentId];
        return question.visibleValues.includes(parentValue);
    }, [formData]);

    const optionTemplate = useCallback((option: any, question: DetectionQuestion) => {
        const questionAIValue = questionValues?.find((q: any) => q.questionId === question.id);
        const aiValue = questionAIValue?.aiValue;
        const aiScore = questionAIValue?.aiScore;
        const rateText = aiValue === option.label ? 
            `${(aiScore * 100).toFixed(0)}%` : null;

        return (
            <div className="flex items-center">
                {rateText && (
                    <span className="mr-2 px-2 py-1 bg-aliceBlue text-trueBlue font-medium text-xs rounded-lg min-w-[40px] text-center">
                        {rateText}
                    </span>
                )}
                <span className="text-gray-700 text-sm">{option.label}</span>
            </div>
        );
    }, [questionValues]);

    const valueTemplate = useCallback((value: { label: string, value: string }, question: DetectionQuestion) => {
        if (!value) return 'Select';
        if (!question) return null;

        const questionAIValue = questionValues?.find((q: any) => q.questionId === question.id);
        const aiValue = questionAIValue?.aiValue;
        const aiScore = questionAIValue?.aiScore;
        const rateText = aiValue === value.value ?
            `${(aiScore * 100).toFixed(0)}%` : null;

        return (
            <div className="flex items-center">
                 {rateText && <img
                    className="mr-1"
                    src={'/img/gemini-icon-2.png'}
                    style={{ width: '24px', height: '24px' }}
                    alt="star"
                 /> }
                {rateText && (
                    <span className="mr-2 px-2 py-1 bg-aliceBlue text-trueBlue font-medium text-xs rounded-lg min-w-[40px] text-center">
                        {rateText}
                    </span>
                )}
                <span className="text-gray-700 text-sm">{value.label}</span>
            </div>
        );
    }, [questionValues]);


    const sortedQuestions = useMemo(() => {
        if (!filteredQuestions?.length) return [];
        return [...filteredQuestions].sort((a, b) => (a?.orderPoint || 0) - (b?.orderPoint || 0));
    }, [filteredQuestions]);

    if (!sortedQuestions?.length) return null;

    const handleSubmit = async () => {
        try {
            dispatch(approveDetection({
                id: selectedDetection?.id,
                status: 'Approved'
            })).then(() => {
                dispatch(setSelectedDetection(null))
                dispatch(setSelectedImage({ index: 0, reviewData, selectedTable }))
            })
        } catch (error) {
            console.error('Error updating questions:', error);
        }
    };

    const handleReject = () => {
        dispatch(setSelectedDetection(null));
    };


    return (
        <div className="flex flex-col h-full justify-between">
        <div className="flex flex-col rounded-lg bg-aliceBluePurple mt-6 border border-chineseWhite">
            <div className="flex flex-col py-4 pl-4 pr-2">
                <div className="flex flex-row font-medium items-center text-base justify-between text-arsenic w-full">
                {DETECTION_TYPE_MAPPING[selectedDetection?.detectionType] || selectedDetection?.detectionType}
                    <StatusBadge status={selectedDetection?.status} size="sm" />
                </div>
                {sortedQuestions.map((question: DetectionQuestion) => (
                    isQuestionVisible(question) && (
                        <div className="flex flex-col mt-5" key={question.id}>
                            <label
                                htmlFor={`question-${question.id}`}
                                className="text-sm font-medium text-graniteGray mb-2"
                            >
                                {question.name}
                                {question.hasSubAssetCount && " (Per Sub Asset)"}
                            </label>
                            <Dropdown
                                id={`question-${question.id}`}
                                options={generateDropdownOptions(question.options)}
                                value={formData[question.id]?.value || null}
                                onChange={(e) => handleChange(question.id, e.value, question)}
                                placeholder="Select"
                                className="w-full custom-dropdown"
                                itemTemplate={(option) => optionTemplate(option, question)}
                                valueTemplate={(value) => valueTemplate(value, question)}
                            />
                            {(() => {
                                const questionAIValue = questionValues?.find((q: any) => q.questionId === question.id);
                                const aiValue = questionAIValue?.aiValue;
                                const userValue = questionAIValue?.user?.name;
                    
                                return userValue && (
                                    <div className="mt-1 text-xs text-gray-500">
                                        *AI response was "{aiValue}" and overridden by {userValue}
                                    </div>
                                );
                            })()}
                        </div>
                    )
                ))}
            </div>
        </div>
        <div className="flex flex-row justify-end ml-auto w-full">
                <ActionButtons
                    isActive={false}
                    handleSubmit={handleSubmit}
                    handleReject={handleReject}
                />
            </div>
        </div>
    );
};
