// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getComments,
    getQuestions,   
    getReviewData,
    RootState,
    setMaskImage,
    setReviewMainImageLoading
} from '@/store'
import ReviewTitle from './ReviewTitle'
import ReviewRightPanel from './ReviewRightPanel'
import ReviewMap from './ReviewMap'
import ReviewImage from './ReviewImage'
import Button from '@/components/ui/Button'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import DrawImage from './DrawImage'

const ReviewView = () => {
    const dispatch = useDispatch()

    const { reviewShow, maskImage, reviewData } = useSelector(
        (state: RootState) => state.review,
    )

    useEffect(() => {
        if (reviewData?.assetDetail && reviewShow) {
            dispatch(getComments({ asset: reviewData?.assetDetail, imageId: null }))
            dispatch(getQuestions())
        }
    }, [reviewData, reviewShow])

    return (
        <div
            className={`${
                !reviewShow
                    ? 'hidden'
                    : 'w-full md:h-[100%] h-max absolute bg-antiFlashWhite z-20 font-roboto'
            }`}
        >
            <ReviewTitle />
            <div className="grid grid-cols-11 gap-2">
                <div className="col-span-2 pl-2">
                    <ReviewMap />
                </div>
                <div className="col-span-6 relative">
                    <DrawImage />
                </div>
                <div className="col-span-3">
                    <ReviewRightPanel />
                </div>
            </div>
        </div>
    )
}

export default ReviewView
