// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { type AxiosError, AxiosResponse } from 'axios'
import BaseService from '@/services/BaseService'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface commentState {
    comments: any[]
    commentsImage: any[]
    commentsLoading: boolean
    loading: boolean
    lastUpdateId: number | null
    editCommentId: number | null
    selectedReplyId: number | null
    replyCommentId: number | null
}

const initialState: commentState = {
    comments: [],
    commentsImage: [],
    commentsLoading: false,
    loading: false,
    lastUpdateId: null,
    editCommentId: null,
    selectedReplyId: null,
    replyCommentId: null,
}

export const getComments = createAsyncThunk(
    '/micro-fe/comments',
    async (
        { asset, imageId }: { assetId: number; imageId: number },
        { rejectWithValue, getState },
    ) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/comments`,
            method: 'get',
            params: {
                tenantId: tenantId,
                token: token,
                assetId: asset?.asset.id,
                inspectionId: asset?.inspections[0]?.id,
                imageId,
            },
        };

        try {
            const response: AxiosResponse = await BaseService(param);
            return response.data.comments;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const createComment = createAsyncThunk<
    void,
    {
        asset: any
        imageId: number
        message: string
        label: string
    },
    { rejectValue: string }
>(
    'comments/createComment',
    async (
        { asset, imageId, message, label },
        { rejectWithValue, getState, dispatch },
    ) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/create-comment`,
            method: 'post',
            data: {
                tenantId: tenantId,
                token: token,
                assetId: asset?.asset.id,
                inspectionId: asset?.inspections[0]?.id,
                imageId,
                message,
                label,
            },
        };

        try {
            await BaseService(param);
            dispatch(getComments({ asset: state.review.reviewData?.assetDetail, imageId: imageId }));
        } catch (error: any) {
            const axiosError: AxiosError = error;
            return rejectWithValue(axiosError.message);
        }
    },
);

export const deleteComment = createAsyncThunk<
    void,
    { commentId: number; asset: any; imageId: number },
    { rejectValue: string }
>(
    'comments/deleteComment',
    async ({ commentId, asset, imageId }, { rejectWithValue, getState, dispatch }) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/delete-comment/${commentId}?tenantId=${tenantId}&token=${token}`,
            method: 'delete',
        };

        try {
            await BaseService(param);
            dispatch(getComments({ asset: state.review.reviewData?.assetDetail, imageId: imageId }));
        } catch (error: any) {
            const axiosError: AxiosError = error;
            console.error('errors', axiosError);
            return rejectWithValue(axiosError.message);
        }
    },
);

export const updateComment = createAsyncThunk<
    { lastUpdateId: number },
    { id: number; message: string; coordinate: string; asset: any; imageId: number },
    { rejectValue: string }
>(
    'comments/updateComment',
    async ({ id, message, coordinate, asset, imageId }, { rejectWithValue, getState, dispatch }) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/update-comment`,
            method: 'patch',
            data: {
                id,
                tenantId: tenantId,
                token: token,
                assetId: asset?.asset.id,
                inspectionId: asset?.inspections[0]?.id,
                imageId,
                message,
                label: coordinate,
            },
        };

        try {
            const response: AxiosResponse = await BaseService(param);
            const lastUpdateId = response.data?.id;

            dispatch(getComments({ asset: state.review.reviewData?.assetDetail, imageId: imageId }));
            return { lastUpdateId };
        } catch (error: any) {
            const axiosError: AxiosError = error;
            console.error('errors', axiosError);
            return rejectWithValue(axiosError.message);
        }
    },
);

export const createReplyComment = createAsyncThunk<
    void,
    { commentId: number; message: string; asset: any; imageId: number },
    { rejectValue: string }
>(
    'comments/createReplyComment',
    async ({ commentId, message, asset, imageId }, { rejectWithValue, getState, dispatch }) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/create-reply`,
            method: 'post',
            data: {
                tenantId: tenantId,
                token: token,
                commentId,
                replyTime: new Date(),
                message,
            },
        };

        try {
            await BaseService(param);
            dispatch(getComments({ asset: state.review.reviewData?.assetDetail, imageId: imageId }));
        } catch (error: any) {
            const axiosError: AxiosError = error;
            console.error('errors', axiosError);
            return rejectWithValue(axiosError.message);
        }
    },
);

export const updateReplyComment = createAsyncThunk<
    void,
    { id: number; message: string; commentId: number; asset: any; imageId: number },
    { rejectValue: string }
>(
    'comments/updateReplyComment',
    async ({ id, message, commentId, asset, imageId }, { rejectWithValue, getState, dispatch }) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/update-reply/${id}`,
            method: 'patch',
            data: {
                id,
                tenantId: tenantId,
                token: token,
                replyTime: new Date(),
                message,
                commentId,
            },
        };

        try {
            await BaseService(param);
            dispatch(getComments({ asset: state.review.reviewData?.assetDetail, imageId: imageId }));
        } catch (error: any) {
            const axiosError: AxiosError = error;
            return rejectWithValue(axiosError.message);
        }
    },
);

export const deleteReplyComment = createAsyncThunk<
    void,
    { id: number; asset: any; imageId: number },
    { rejectValue: string }
>(
    'comments/deleteReplyComment',
    async ({ id, asset, imageId }, { rejectWithValue, getState, dispatch }) => {
        const state = getState() as RootState;
        const tenantId = state.global?.tenantId;
        const token = state.global?.token;

        if (!tenantId || !token) {
            throw new Error('Missing required authentication data');
        }

        const param = {
            url: `/micro-fe/delete-reply/${id}?tenantId=${tenantId}&token=${token}`,
            method: 'delete',
        };

        try {
            await BaseService(param);
            dispatch(getComments({ asset: state.review.reviewData?.assetDetail, imageId: imageId }));
        } catch (error: any) {
            const axiosError: AxiosError = error;
            console.error('errors', axiosError);
            return rejectWithValue(axiosError.message);
        }
    },
);

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        setEditCommentId: (state, action: PayloadAction<string>) => {
            state.editCommentId = action.payload;
        },
        setSelectedReplyId: (state, action: PayloadAction<string>) => {
            state.selectedReplyId = action.payload;
        },
        setReplyCommentId: (state, action: PayloadAction<string>) => {
            state.replyCommentId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getComments.pending, (state) => {
                state.commentsLoading = true;
            })
            .addCase(getComments.fulfilled, (state, action) => {
                state.commentsLoading = false;
                state.comments = action.payload;
                console.log('action.payload', action.payload)
                state.commentsImage = action.payload?.filter(
                    (comment) => comment?.imageId === action.meta.arg.imageId,
                );
            })
            .addCase(getComments.rejected, (state) => {
                state.commentsLoading = false;
            });
        builder
            .addCase(deleteComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteComment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteComment.rejected, (state) => {
                state.loading = false;
            });
        builder
            .addCase(createComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(createComment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(createComment.rejected, (state) => {
                state.loading = false;
            });
        builder
            .addCase(updateComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateComment.fulfilled, (state, action) => {
                state.loading = false;
                state.lastUpdateId = action.payload.lastUpdateId;
            })
            .addCase(updateComment.rejected, (state) => {
                state.loading = false;
            });
        builder
            .addCase(createReplyComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(createReplyComment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(createReplyComment.rejected, (state) => {
                state.loading = false;
            });
        builder
            .addCase(updateReplyComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateReplyComment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(updateReplyComment.rejected, (state) => {
                state.loading = false;
            });
        builder
            .addCase(deleteReplyComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteReplyComment.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(deleteReplyComment.rejected, (state) => {
                state.loading = false;
            });
    },
});

export const { setEditCommentId, setSelectedReplyId, setReplyCommentId } = commentSlice.actions;

export default commentSlice.reducer;
