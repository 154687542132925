import { Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import React, { useEffect } from 'react';
import './App.css';
import ModuleReview from './modules/review-2/ModuleReview';
import { MODULE_REVIEW } from './global/consts';
import { initServiceToken } from './global/BaseService';
import store, { persistor } from './store'
import { setModuleName, setId, setToken, setTenantId } from './store/slices/dashboard/globalSlice'
import { RootState } from './store/rootReducer';

const AppContent = () => {
  const dispatch = useDispatch();
  const moduleName = useSelector((state: RootState) => state.global.moduleName);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    dispatch(setModuleName(urlParams.get('moduleName') || ''));
    dispatch(setId(urlParams.get('id') || '0'));
    dispatch(setToken(urlParams.get('token') || ''));
    dispatch(setTenantId(parseInt(urlParams.get('tenantId') || '0')));

    if (urlParams.get('token')) {
      initServiceToken(urlParams.get('token') || '');
    }

    if (window.location.search) {
      // window.history.replaceState(null, '', '/')
    }
  }, [dispatch]);

  return (
    <div className="App">
      <header className="App-header">
        {moduleName === MODULE_REVIEW && <ModuleReview />}
        {/* {!MODULES.includes(moduleName) && <p>No modules specified !</p>} */}
      </header>
    </div>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AppContent />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
