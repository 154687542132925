    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { StorageAccess } from "./hooks/useStorageAccess";

export function addFileNameSuffix(fileName: string, suffix: string): string {
    const lasPointPos = fileName.lastIndexOf('.');
    const previous = fileName.substring(0, lasPointPos);
    // const next = fileName.substring(lasPointPos);
    return previous + suffix + '.webp';
}

export const getPreviewBlobName = (blobName: string) => addFileNameSuffix(blobName, '_pr')

export const getThumbBlobName = (blobName: string) => addFileNameSuffix(blobName, '_tb')

export const assetTypes = [
    'Circuit',
    'Pole',
].map(value => ({ label: value, value }));

export enum DefectType {
    Lean = 'Lean',
    Rot = 'Rot',
    Cracks = 'Cracks',
    Splices = 'Splices',
    Broken = 'Broken',
    Leaking = 'Leaking',
    Damage = 'Damage',
    Loose = 'Loose',
}

export const vegetationDefectTypes = [
    { name: 'Tree Encroachment'},
    { name: 'Dead or Dying Trees'},
    { name: 'Vegetation Contacting Electrical Components'},
    { name: 'High-Risk Trees (Leaning or Unstable Trees)'},
    { name: 'Blocked Access Due to Overgrowth'},
].map(val => ({ label: val.name, value: val.name,  }));

export const vegetationComponentTypes = [
    { name: 'Pole'},
    { name: 'Line' },
].map(val => ({ label: val.name, value: val.name }));

export const componentTypes = [
    { name: 'Pole: Monopole', types: [DefectType.Cracks, DefectType.Broken, DefectType.Damage, DefectType.Lean ] },
    { name: 'Pole: H-Frame', types: [DefectType.Cracks, DefectType.Broken, DefectType.Damage ] },
    { name: 'Pole: Lattice', types: [DefectType.Cracks, DefectType.Broken, DefectType.Damage ] },
    { name: 'Insulator', types: [DefectType.Cracks, DefectType.Broken, DefectType.Damage ] },
    { name: 'Cross Arm', types: [DefectType.Cracks, DefectType.Broken, DefectType.Damage ] },
    { name: 'Transformer', types: [DefectType.Cracks, DefectType.Broken, DefectType.Damage, DefectType.Leaking ] },
    { name: 'Arrestor', types: [] },
    { name: 'Ground Wire', types: [DefectType.Damage, DefectType.Loose ] },
    { name: 'Guy Wire', types: [DefectType.Damage, DefectType.Loose ] },
    { name: 'Conductor', types: [] },
    { name: 'Static Wire', types: [DefectType.Damage, DefectType.Loose ] },
].map(val => ({ label: val.name, value: val.name, types: val.types }));

export const defectTypes = [
    DefectType.Lean.toString(),
    DefectType.Rot.toString(),
    DefectType.Cracks.toString(),
    DefectType.Splices.toString(),
    DefectType.Broken.toString(),
    DefectType.Leaking.toString(),
    DefectType.Damage.toString(),
    DefectType.Loose.toString(),
].map(value => ({ label: value, value }));

export const priorities = [
    1,
    2,
    3,
    4,
].map(value => ({ label: 'P'+value, value }));

export function getPriorityColor(priorty: number): string {
    let color= '#EC4830';
    if (priorty == 2) color = '#FFE2E0';
    if (priorty == 3) color = '#FC880E';
    if (priorty == 4) color = '#F5C145';
    return color;
}
export function getSeverityColor(severity: number): string {
    let color= '#FF6347';
    if (severity == 2) color = '#FF4500';
    if (severity == 3) color = '#DC143C';
    if (severity == 4) color = '#B22222';
    if (severity == 5) color = '#8B0000';
    return color;
}


export function getVegetationPriorityColor(priorty: number): string {
    let color= '#EC4830';
    if (priorty == 2) color = '#FFE2E0';
    if (priorty == 3) color = '#FC880E';
    if (priorty == 4) color = '#F5C145';
    return color;
}
export function getVegetationSeverityColor(severity: number): string {
    let color= '#F5C145';
    if (severity == 3) color = '#FFE2E0';
    if (severity == 2) color = '#FFB8B3';
    if (severity == 1) color = '#E3341B';
    return color;
}

export function getVegetationMapColor(priorty: number): string {
    let color= '#EC4830';
    if (priorty == 2) color = '#FC880E';
    if (priorty == 3) color = '#F5C145';
    if (priorty == 4) color = '#b3b3b3';
    return color;
}

export enum IsApp {
    Local = 'http://localhost:5173',
    ThreeV = 'https://app.threev.ai',
    PGE = 'https://pge.threev.ai',
}

export function isApp(isApp: IsApp) {
    return "https://pge.threev.ai" === isApp;
}

export function getBlobSasUrl(storageAccess: StorageAccess, blobName: string) {
    return storageAccess.token.replace('/?', `/${storageAccess.storageName}/${blobName}?`);
}

export const DETECTION_TYPE_MAPPING: Record<string, string> = {
    'TransmissionTowerDetection': 'Transmission Tower Detected',
    'ServiceDropDown': 'Service Drop Detected',
    'WireDetection': 'Wire Detection Detected',
    'TransformerDetection': 'Transformer Detected',
    'InsulatorDetection': 'Insulator Detected'
};