import { useEffect, useState } from "react";
import BaseService from '@/global/BaseService'
import { AxiosError, AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

export type GeoserverAccess = {
    token: string | null
}

export default function useGeoserverAccess() {
    const [access, setAccess] = useState<GeoserverAccess>( { token: null } );

    const tenantId = useSelector((state: RootState) => state.global.tenantId);
    const token = useSelector((state: RootState) => state.global.token);

    useEffect(() => {
        handleGetAccess();
        const interval = setInterval(() => {
            handleGetAccess();
        }, 59 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    async function handleGetAccess() {
        const param = {
            url: '/micro-fe/geoserver-access',
            method: 'get',
            params: {
                tenantId: tenantId,
                token: token,
            },
        };
        BaseService(param)
            .then((response: AxiosResponse<GeoserverAccess>) => {
                setAccess(response.data);
            })
            .catch((errors: AxiosError) => {
                console.log('errors', errors);
            });
    }

    return access;
}
