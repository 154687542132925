// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import {  NotificationDto } from '@/views/asset-library/Inspections'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { handleNotificationAction } from '@/store/slices/dashboard/notificationSlice'
import { getReviewData, setReviewShow, setSelectedTable, setReviewMainImageLoading, setReviewImageThumbsLoading } from '@/store'

export type SidePanelContentProps = {
    panelExpand: boolean,
    loading: boolean,
    notifications: NotificationDto[],
    callBackClose?: () => void,
    getNotifications: () => void,
}

const SidePanelContent = ({ callBackClose, notifications }: SidePanelContentProps) => {
    const dispatch = useDispatch();

    // Returns the type of the notification based on its content.
    function getNotificationType(notification: NotificationDto) {
        if (notification.comment) {
            return 'Comment';
        }
        return 'N/A';
    }

    // Handles the click event for viewing a notification
    async function handleViewClick(notification) {
        await dispatch(handleNotificationAction({ notification, actionTaken: "clicked_review" }));
        await dispatch(getReviewData({asset: notification.asset.code}));
        dispatch(setReviewShow(true));
        dispatch(setSelectedTable(notification.asset))
        dispatch(setReviewMainImageLoading(true))
        dispatch(setReviewImageThumbsLoading(true))
        callBackClose();
    }

    // Handles the click event for ignoring a notification
    async function handleIgnoreClick(notification) {
        await dispatch(handleNotificationAction({ notification, actionTaken: "clicked_ignore" }));
        callBackClose();
    }

    return (
        <div className="w-[350px] bg-white border rounded-lg border-customBorderColor box-content releative">
            {/* Header section of the notifications container */}
            <div className="h-[40px] flex items-center box-border border-b pl-4">
                <p className="font-dmsans font-semibold	text-customTextColor pt-0.5">Notifications</p>
            </div>
            <div>
         
            {!notifications.length && <span className="text-center flex justify-center items-center h-[100px]">No notifications</span>}
                {/* Iterates over notifications array and renders each notification */}
                {notifications.map(notification => <div key={notification.id} className="mb-4 border-0 pl-4 pt-2" >
                <div className="font-roboto">
                    {/* Notification indicator */}
                    <div className="flex flex-row items-center h-5 items-center">
                        <span className={`${notification?.read ? "bg-gray-400" : "bg-blue-500"} h-1.5 w-1.5 rounded-full mr-1 ml-1`} />
                        <p className="font-semibold text-sm">{getNotificationType(notification)} Found</p>
                    </div>
                    {/* Notification message */}
                    <div className="text-sm pl-4 text-xs h-5 items-center">
                        {notification.comment?.message.split('\n').map((text, index) => <React.Fragment key={index}>
                            {text}
                        </React.Fragment>)}
                    </div>
                    {/* Additional details about the notification */}
                    <div className="pl-4 text-xs items-center">
                        <div>{notification?.comment?.assetId} -</div>
                    </div>
                    {/* Action and timestamp */}
                    <div className="flex flex-row items-center pl-4 text-xs h-5 items-center">
                        <div className="text-notificationBlue cursor-pointer" onClick={() => handleViewClick(notification)}>Review</div>
                        <span className="h-1 w-1 rounded-full bg-notificationGray mr-1 ml-1" />
                        <div className="cursor-pointer" onClick={() => handleIgnoreClick(notification)}>Ignore</div>
                        <span className="h-1 w-1 rounded-full bg-notificationGray mr-1 ml-1" />
                        <div>{dayjs(notification.notificationTime).format('MM/DD/YYYY HH:mm:ss')}</div>
                    </div>
                </div>
            </div>)}
            </div>
        </div>);
}

export default SidePanelContent
